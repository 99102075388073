import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import Provider from 'components/Provider';

import { loadSharedData } from 'containers/sharedStore';
import { loadFrontData } from 'containers/Home/store';
import { loadData as loadPromotionsData } from 'containers/PromotionsList/store';
import Home from 'containers/Home';

class HomePage extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
  };

  static getInitialProps = async ({ reduxStore, req }) => {
    const sharedData = await reduxStore.dispatch(loadSharedData());
    const frontData = await reduxStore.dispatch(loadFrontData());
    const promotionsData = await reduxStore.dispatch(loadPromotionsData());

    if (req) {
      return {
        ...sharedData,
        ...frontData,
        ...promotionsData,
      };
    }

    return null;
  };

  render() {
    return <Home url={this.props.router} date={new Date().getTime()} />;
  }
}

export default withRouter(Provider(HomePage));
